import Vue from 'vue'
import moment from 'moment'
import { type } from 'os';
const crypto = require('crypto');

export const KEY = 'EncryptKey123456'

function decrypt(data) {
	try {
			data = data.replace(" ","+");
			const iv = Buffer.alloc(16, 0);
			var cipher = crypto.createDecipheriv('aes-128-cbc', KEY, iv);
			var str = decodeURI(cipher.update(data, 'base64', 'utf8') + cipher.final('utf8'));
			return str.replaceAll("+", " ")
	} catch(error) {
			//console.log("DECRYPT ERROR - " + error)
	}
	return null;
}


export default

Vue.filter('currecry', function (value) {
    if (!value) return '0'
    return parseFloat(value).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  })
Vue.filter('number', function (value) {
    if (!value) return '0'
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  })
Vue.filter('currecryZero', function (value) {
  if (!value) return '0'
  return parseFloat(value).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
})
Vue.directive('uppercase', {
  update (el, binding, vnode) {
      el.value = el.value.toUpperCase()
  }
})
Vue.filter('decrypt', function (value) {
  return decrypt(value + '')
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD hh:mm:ss')
  }
})

Vue.filter('formatD', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
})
