import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from 'vuex-shared-mutations'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      sv_token: '',
      sv_email: '',
      permission: [],
      user: null,
      menu: [],
      locale: 'mn',
      loan_type_options: [],
      loan_type: {}
    }, 
    mutations: {
        INIT (state) {
            state.sv_token = ''
            state.sv_email = '',
            state.permission = [],
            state.user = null,
            state.menu = [],
            state.locale = 'mn'
        },
        TOKEN (state, token) {
            state.sv_token = token
        },
        EMAIL (state, emailAddress) {
            state.sv_email = emailAddress
        },
        USER (state, user) {
            state.user = user
        },
        DATA (state, data) {
            state.sv_token = data.token
            state.sv_email = data.emailAddress
            state.user = {
                role: {
                    id: data.role,
                    code: data.code,
                    name: data.name
                },
                firstname: data.firstname,
                lastname: data.lastname,
                language: data.language,
                email:  data.emailAddress,
                id:  data.id
            } 
            state.permission = data.permissions
            state.menu = data.menu
            state.locale = data.language
            state.phone = data.phone
        },
        PERMISSION (state, permission) {
            state.permission = permission
        },
        MENU (state, menu) {
            state.menu = menu
        },
        LOCALE (state, locale) {
            state.locale = locale
        },
        DESTROY (state) {
            state.sv_token = ''
            state.sv_email = '',
            state.permission = [],
            state.user = null,
            state.menu = [],
            state.locale = 'mn'
        },
        ADD_STATE (state, data) {
            state[data['key']] = data['value']
        },
        LOAN_TYPE_OPTIONS (state, options) {
            state.loan_type_options = options
        },
        LOAN_TYPE (state, type_list) {
            state.loan_type = type_list
        },
    },
    getters: {
      token: state => state.sv_token,
      email: state => state.sv_email,
      permission: state => state.permission,
      loan_type_options: state => state.loan_type_options,
      loan_type: state => state.loan_type,
      user: state => state.user,
      menu: state => state.menu,
      locale: state => state.locale,
      fullname: state => {
        return (state.user != null) ? ((state.user.lastname != undefined?(state.user.lastname + ' '):'') + state.user.firstname) : ''
      },
      nicename: state => {
        return (state.user != null) ? ((state.user.lastname != undefined?(state.user.lastname + '.'):'') + state.user.firstname) : ''
      },
      phone: state => state.phone,
      httpHeader: state => {
          return  { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Token': state.sv_token,
            'X-Email': state.sv_email
          }
      },
      httpFileHeader: state => {
        return  { 
            'Content-Type': 'multipart/form-data;application/x-www-form-urlencoded',
            'X-Token': state.sv_token,
            'X-Email': state.sv_email
        }
    }
    },
    plugins: [createPersistedState(), createMutationsSharer({ predicate: [] })]
  })
